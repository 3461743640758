import { default as gameOEIT7QIK4IMeta } from "/home/gitlab/builds/Xh5g_NY8/0/scratch-book/deploy/app/pages/game.vue?macro=true";
import { default as indextThx2Ks2OVMeta } from "/home/gitlab/builds/Xh5g_NY8/0/scratch-book/deploy/app/pages/index.vue?macro=true";
import { default as private_45policyQZN6wAN5aqMeta } from "/home/gitlab/builds/Xh5g_NY8/0/scratch-book/deploy/app/pages/private-policy.vue?macro=true";
import { default as rankcgPF2HfjhRMeta } from "/home/gitlab/builds/Xh5g_NY8/0/scratch-book/deploy/app/pages/rank.vue?macro=true";
import { default as settingWwP2Dxn7v8Meta } from "/home/gitlab/builds/Xh5g_NY8/0/scratch-book/deploy/app/pages/setting.vue?macro=true";
import { default as sharej3h1745oOIMeta } from "/home/gitlab/builds/Xh5g_NY8/0/scratch-book/deploy/app/pages/share.vue?macro=true";
import { default as terms4fwL3tLMNDMeta } from "/home/gitlab/builds/Xh5g_NY8/0/scratch-book/deploy/app/pages/terms.vue?macro=true";
import { default as tutorialoWnNHwEulQMeta } from "/home/gitlab/builds/Xh5g_NY8/0/scratch-book/deploy/app/pages/tutorial.vue?macro=true";
export default [
  {
    name: "game",
    path: "/game",
    component: () => import("/home/gitlab/builds/Xh5g_NY8/0/scratch-book/deploy/app/pages/game.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/gitlab/builds/Xh5g_NY8/0/scratch-book/deploy/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "private-policy",
    path: "/private-policy",
    component: () => import("/home/gitlab/builds/Xh5g_NY8/0/scratch-book/deploy/app/pages/private-policy.vue").then(m => m.default || m)
  },
  {
    name: "rank",
    path: "/rank",
    component: () => import("/home/gitlab/builds/Xh5g_NY8/0/scratch-book/deploy/app/pages/rank.vue").then(m => m.default || m)
  },
  {
    name: "setting",
    path: "/setting",
    component: () => import("/home/gitlab/builds/Xh5g_NY8/0/scratch-book/deploy/app/pages/setting.vue").then(m => m.default || m)
  },
  {
    name: "share",
    path: "/share",
    component: () => import("/home/gitlab/builds/Xh5g_NY8/0/scratch-book/deploy/app/pages/share.vue").then(m => m.default || m)
  },
  {
    name: "terms",
    path: "/terms",
    component: () => import("/home/gitlab/builds/Xh5g_NY8/0/scratch-book/deploy/app/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: "tutorial",
    path: "/tutorial",
    component: () => import("/home/gitlab/builds/Xh5g_NY8/0/scratch-book/deploy/app/pages/tutorial.vue").then(m => m.default || m)
  }
]