import axios from 'axios';
import { useStorage } from '~/composables/useStorage'
import JSEncrypt from 'jsencrypt'
import { ScratchApi } from '~/apis/ScratchApi'
import { useApp } from '~/composables/useApp'

export default defineNuxtPlugin((nuxtApp) => {
  const PUBLIC_KEY = "PUBLIC_KEY";
  const { API_URL, API_KEY } = useRuntimeConfig().app

  const $axios = axios.create({
    baseURL: API_URL,
    withCredentials: true,
  });

  $axios.interceptors.request.use(
    async (config) => {
      let publicKey = useStorage().session.get(PUBLIC_KEY);
      if (!publicKey) {
        try {
          const { data } = await axios.get("/api/v1.0/.public", {
            baseURL: API_URL || "http://localhost:8080",
          });
          publicKey = data;
          useStorage().session.put(PUBLIC_KEY, publicKey);
        } catch (e: any) {
          console.error("Failed to obtain public key", e);
        }
      }
      let id: string = await useApp().device();
      if (publicKey) {
        const jsencrypt = new JSEncrypt();
        const message = `${id}@@${API_KEY}`;
        jsencrypt.setPublicKey(publicKey);
        config.headers["Authorization"] = `Bearer ${jsencrypt.encrypt(message)}`;
      }
      return config;
    },
    async (error: any) => {
      return Promise.reject(error)
    }
  )

  $axios.interceptors.response.use(
    (response: any) => {
      return response.data;
    },
    async (error: any) => {
      if (error?.response?.status === 401) {
        return $axios(error.config);
      }
      return Promise.reject(error);
    }
  )

  interface API {
    scratchApi: ScratchApi
  }

  const api: API = {
    scratchApi: new ScratchApi($axios)
  }

  nuxtApp.vueApp.provide('globalApi', api)
})
