import { v4 as uuid } from "uuid";

import { STORAGES, type SETTING } from '~/types/constants'

export function useApp() {
  return {
    type() {
      const mobileType = navigator.userAgent.toLowerCase();
      if (mobileType.indexOf("android") !== -1) {
        return "aos";
      } else if (
        mobileType.indexOf("iphone") > -1 ||
        mobileType.indexOf("ipad") > -1 ||
        mobileType.indexOf("ipod") > -1
      ) {
        return "ios";
      } else {
        return "web";
      }
    },
    async device(): Promise<string> {
      return new Promise(function (resolve) {
        const type = useApp().type();
        try {
          let id = useStorage().local.get("ID");
          if (id) {
            resolve(id);
          } else {
            if (type === "aos") {
              // @ts-ignore
              id = window.skyDroid.getDeviceUUID();
              useStorage().local.put("ID", id);
              resolve(id);
            } else if (type === "ios") {
              // @ts-ignore
              window.getDeviceId = function (deviceId: string) {
                id = deviceId;
                useStorage().local.put("ID", id);
                resolve(id);
              };
              // @ts-ignore
              window.webkit.messageHandlers.getDeviceUUID.postMessage("");
            } else {
              id = uuid();
              useStorage().local.put("ID", id);
              resolve(id);
            }
          }
        } catch (e: any) {
          const id = uuid();
          useStorage().local.put("ID", id);
          resolve(id);
        }
      });
    },
    isApp() {
      try {
        const type = useApp().type();
        if (type === "aos") {
          // @ts-ignore
          window.skyDroid.getDeviceUUID();
          return true;
        } else if (type === "ios") {
          // @ts-ignore
          window.webkit.messageHandlers.getDeviceUUID.postMessage("");
          return true;
        }
      } catch (e: any) {}
      return false;
    },
    ad: {
      reward(): boolean {
        const type = useApp().type();
        try {
          if (type === "aos") {
            // @ts-ignore
            window.skyDroid.showRewardAd();
            return true;
          } else if (type === "ios") {
            // @ts-ignore
            window.webkit.messageHandlers.showRewardAd.postMessage("");
            return true;
          }
        } catch (e: any) {}
        return false;
      },
      interstitial() {
        const type = useApp().type();
        try {
          if (type === "aos") {
            // @ts-ignore
            window.skyDroid.showInterstitialAd();
          } else if (type === "ios") {
            // @ts-ignore
            window.webkit.messageHandlers.showInterstitialAd.postMessage("");
          }
        } catch (e: any) {}
      },
    },
    showExitToast() {
      const type = useApp().type();
      try {
        if (type === "aos") {
          // @ts-ignore
          window.skyDroid.showExitToast();
        } else if (type === "ios") {
          // @ts-ignore
          window.webkit.messageHandlers.showExitToast.postMessage("");
        }
      } catch (e: any) {}
    },
    exitApp() {
      const type = useApp().type();
      try {
        if (type === "aos") {
          // @ts-ignore
          window.skyDroid.exitApp();
        } else if (type === "ios") {
          // @ts-ignore
          window.webkit.messageHandlers.exitApp.postMessage("");
        }
      } catch (e: any) {}
    },
    haptic(strong: number = 50, time: number = 100) {
      const settings = getSettings();
      console.log("Hatpic", settings.haptic, strong, time);
      if (!settings.haptic) return;

      const type = useApp().type();
      try {
        if (type === "aos") {
          let value = Math.round((strong / 100) * 255);
          // @ts-ignore
          window.skyDroid.haptic(value, time);
        } else if (type === "ios") {
          let value = strong / 100;
          // @ts-ignore
          window.webkit.messageHandlers.haptic.postMessage(
            JSON.stringify({ strong: value, time })
          );
        }
      } catch (e: any) {}
    },
    shareUrl(text: string = "") {
      const type = useApp().type();
      try {
        if (type === "aos") {
          // @ts-ignore
          window.skyDroid.shareUrl(text);
        } else if (type === "ios") {
          // @ts-ignore
          window.webkit.messageHandlers.shareUrl.postMessage(
            JSON.stringify({ text })
          );
        } else {
          // @ts-ignore
          window.skyDroid.shareUrl(text);
        }
      } catch (e: any) {}
    },
    getdevicefcm() {
      return new Promise(function (resolve) {
        const type = useApp().type();
        try {
          if (type === "aos") {
            // @ts-ignore
            const fcmToken = window.skyDroid.getFcmToken();
            resolve(fcmToken)
          } else if (type === "ios") {
            // @ts-ignore
            window.getFcmToken = function(fcmToken: string) {
              resolve(fcmToken)
            };
            // @ts-ignore
            window.webkit.messageHandlers.getFcmToken.postMessage("");
          } else {
            resolve('')
          }
        } catch (e: any) {
        }
      })
    },
    getPushPermission() {
      return new Promise(function (resolve) {
        const type = useApp().type();
        try {
          if (type === "aos") {
            // @ts-ignore
            const allowPush = window.skyDroid.getNotiPermission();
            resolve(allowPush)
          } else if (type === "ios") {
            // @ts-ignore
            window.getNotiPermission = (allowPush: boolean) => {
              resolve(allowPush)
            }
            // @ts-ignore
            window.webkit.messageHandlers.getNotiPermission.postMessage("");
          } else {
            resolve(true)
          }
        } catch (e: any) {
        }
      })
    },
  };
}

function getSettings(): SETTING {
  const setting: SETTING = {
    bgm: true,
    se: true,
    haptic: true,
  }
  return (useStorage().local.get(STORAGES.SETTING) || setting);
}
