<script setup lang="ts">
const api: any = inject('globalApi')
const tutorial = ref<boolean>(await api.scratchApi.getAppTutorial() || false);
useStorage().local.put('tutorial', tutorial.value)
const path = useRoute().path;
const isApp = useApp().isApp();

if (
  !isApp &&
  !path.startsWith("/private-policy") &&
  !path.startsWith("/terms")
) {
  if (!process.dev) {
    useRouter().replace("/share");
  }
} else if (isApp && !tutorial.value) {
  useRouter().replace("/tutorial");
}
</script>

<template>
  <NuxtPage />
</template>
