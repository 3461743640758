export class ScratchApi {
  axios: any
  constructor(axios: any) {
    this.axios = axios
  }
  getAppTutorial = async () => {
    return this.axios({
      url: '/api/v1.0/app/tutorial',
      method: 'get',
    })
  }
  setApplicationInfo = async (data: any) => {
    return this.axios({
      url: '/api/v1.0/app',
      method: 'post',
      data,
    })
  }
  setPushToken = async (data: any) => {
    return this.axios({
      url: '/api/v1.0/app/push_token',
      method: 'post',
      data,
    })
  }
  setPush = async (data: any) => {
    return this.axios({
      url: '/api/v1.0/app/push',
      method: 'post',
      data,
    })
  }
  setSetting = async (data: any) => {
    return this.axios({
      url: '/api/v1.0/app/setting',
      method: 'post',
      data,
    })
  }
  getUserProfile = async () => {
    return this.axios({
      url: '/api/v1.0/profile',
      method: 'get',
    })
  }
  getScratchInfo = async () => {
    return this.axios({
      url: '/api/v1.0/scratch/info',
      method: 'get',
    })
  }
  getCategoryList = async () => {
    return this.axios({
      url: `/api/v1.0/scratch/category`,
      method: "get",
    })
  }
  getScore = async () => {
    return this.axios({
      url: '/api/v1.0/scratch/score',
      method: 'get',
    })
  }
  getRank = async () => {
    return this.axios({
      url: '/api/v1.0/scratch/rank',
      method: 'get',
    })
  }
  getScratchGameInfo = async (params: any) => {
    return this.axios({
      url: '/api/v1.0/scratch',
      method: 'get',
      params
    })
  }
  checkUserName = async (data: any) => {
    return this.axios({
      url: '/api/v1.0/profile/check/name',
      method: 'post',
      data
    })
  }
  updateProfile = async (data: any) => {
    return this.axios({
      url: '/api/v1.0/profile/app',
      method: 'post',
      data,
      options: {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      }
    })
  }
  changeProfileImage = async (data: any) => {
    return this.axios({
      url: '/api/v1.0/profile/image',
      method: 'post',
      data,
      options: {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      }
    })
  }
  addScratchData = async (data: any) => {
    return this.axios({
      url: '/api/v1.0/scratch',
      method: 'post',
      data
    })
  }
}
