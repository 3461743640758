/**
 * haptic, sound는 값 없을 경우 기본값 설정으로 사용됨
 */
export interface APP_CONTROL {
  is_haptic: boolean,
  haptic?: {
    strong: number,
    time: number
  }
  is_sound: boolean,
  sound?: {
    type: 'BGM' | 'SE',
    name: string,
  },
}

/**
 * page 이동용 Interface
 * haptic, sound는 값 없을 경우 기본값 설정으로 사용됨
 */
export interface MOVE_PAGE extends APP_CONTROL {
  callback?: Function | undefined,
}

/**
 * 진동 및 사운드 설정용
 */
export interface SETTING {
  bgm: boolean;
  se: boolean;
  haptic: boolean;
}

/**
 * 게임 상태
 */
export interface IGameStatus {
  [key: string]: number;
}

/**
 * 정답 입력 키보드
 */
export interface IKeyboard {
  value: string;
  answer: boolean;
  pressed: boolean;
  hint: boolean;
}

/**
 * 모달 팝업
 */
export interface MODAL {
  result: boolean,
  menu: boolean
}

/**
 * 기본 브러쉬 사이즈
 */
export const BRUSH_SIZE = 30
export const STEPS = 10
/**
 * Local Storage 사용될 명칭들
 */
export const STORAGES = {
  CATEGORY: 'scate',
  TOTAL_SCORE: 'totalscore',
  SETTING: "SETTING",
};
