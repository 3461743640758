import { AES, enc } from "crypto-js";

const STORAGE_NAME = "scratch";

interface IStorage {
  [key: string]: any;
}

class CStorage {
  storage: Storage;
  is_prod: boolean

  constructor(storage: Storage, MODE: string) {
    this.storage = storage;
    this.is_prod = MODE === "prod"
  }

  get(key: string): any | null {
    const data = getStorage(this.storage);
    try {
      const message = data[key];
      if (this.is_prod) {
        const json = AES.decrypt(message, STORAGE_NAME).toString(enc.Utf8);
        return JSON.parse(json);
      } else {
        return JSON.parse(message);
      }
    } catch (e: any) {
      return null;
    }
  }

  put(key: string, value: any): void {
    const data = getStorage(this.storage);
    try {
      if (this.is_prod) {
        data[key] = AES.encrypt(JSON.stringify(value), STORAGE_NAME).toString();
      } else {
        data[key] = JSON.stringify(value);
      }
    } catch (e: any) {
      // Ignore errors
    } finally {
      setStorage(this.storage, data);
    }
  }

  remove(key: string): void {
    const data = getStorage(this.storage);
    delete data[key];
    setStorage(this.storage, data);
  }

  cleanup() {
    setStorage(this.storage, {});
  }
}

function getStorage(storage: Storage): IStorage {
  const json = storage.getItem(STORAGE_NAME) || "";
  try {
    return JSON.parse(json);
  } catch (e: any) {
    return {};
  }
}

function setStorage(storage: Storage, data: IStorage): void {
  try {
    const json = JSON.stringify(data);
    storage.setItem(STORAGE_NAME, json);
  } catch (e: any) {
    // Ignore errors
  }
}

export function useStorage() {
  const { MODE } = useRuntimeConfig().app
  return {
    session: new CStorage(window.sessionStorage, MODE),
    local: new CStorage(window.localStorage, MODE),
  };
}
